import axios from "axios";

import { Record } from "@/types";

const apiService = {
    async login(username: string, password: string) {
        const { data: user } = await axios.post("/login", {
            username,
            password,
        });
        return user;
    },
    async authenticate() {
        const { data: user } = await axios.get("/authenticate");
        return user;
    },
    async getAdminRecords(page: number, itemsPerPage = 10, params = {}) {
        const {
            data: {
                "hydra:member": items,
                "hydra:totalItems": totalItems,
                // "hydra:view": {
                //     "hydra:totalPages": totalPages = 1
                // } = {}
            },
        } = await axios.get("/admin/records", { params: { page, itemsPerPage, ...params } });

        let totalUnits = Math.floor(totalItems / itemsPerPage);
        if(totalItems % itemsPerPage > 0) {
            totalUnits += 1;
        }
        const totalPages = totalUnits === 0 ? 1 : totalUnits;

        return { items, totalItems, totalPages };
    },
    async getRecords(page: number, itemsPerPage = 10, params = {}) {
        const {
            data: {
                "hydra:member": items,
                "hydra:totalItems": totalItems,
                // "hydra:view": {
                //     "hydra:totalPages": totalPages = 1
                // } = {}
            },
        } = await axios.get("/records", { params: { page, itemsPerPage, ...params} });

        let totalUnits = Math.floor(totalItems / itemsPerPage);
        if(totalItems % itemsPerPage > 0) {
            totalUnits += 1;
        }
        const totalPages = totalUnits === 0 ? 1 : totalUnits;

        return { items, totalItems, totalPages };
    },
    async getRecord(recordId: string) {
        const {
            data: record,
        } = await axios.get(`/records/${recordId}`);

        return record;
    },
    async editRecord(recordId: string, recordData: any) {
        const {
            data: record,
        } = await axios.put(`/records/${recordId}`, recordData);

        return record;
    },
    async editAndValidateRecord(recordId: string) {
        const {
            data: record,
        } = await axios.put(`/records/${recordId}/validation`, {});

        return record;
    },
    async getRecordColors() {
        const {
            data: { "hydra:member": recordColors },
        } = await axios.get("/colors");

        return recordColors;
    },
    async getBikeTypes() {
        const {
            data: { "hydra:member": bikeTypes },
        } = await axios.get("/types");

        return bikeTypes;
    },
    async recordClosed(recordId: string) {
        const {
            data: record,
        } = await axios.put(`/records/${recordId}/closed`, {});

        return record;
    },
};

export default apiService;
