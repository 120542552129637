
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref, watch} from "vue";
import {useVuelidate} from "@vuelidate/core";
import apiService from "@/services/api-service";
import HeaderNavbar from "@/components/HeaderNavbar.vue";
import PageTrim from "@/components/PageTrim.vue";
import RecordStatus from "@/components/RecordStatus.vue";

export default {
    components: {RecordStatus, HeaderNavbar, PageTrim},
    setup: () => {
        const router = useRouter();
        const route = useRoute();

        const records = ref<[]>([]);

        const recordsCurrentPage = ref<number>();
        const recordsTotalPages = ref<number>(1);

        const recordStatuses = ref<[]>([]);
        const invoiceNumber = ref<string>("");
        const brandName = ref<string>("");
        const brandModel = ref<string>("");
        const paravolNumber = ref<string>("");

        const searchForm = useVuelidate({
            recordStatuses: {},
            invoiceNumber: {},
            brandName: {},
            brandModel: {},
            paravolNumber: {},
        }, { recordStatuses, invoiceNumber, brandName, brandModel, paravolNumber });

        onMounted(async () => {
            let page = 1;

            if (route.query.page && +route.query.page !== 0) {
                page = +route.query.page;
            }
            getRecords(page, true);
        });

        watch([recordStatuses, invoiceNumber, brandName, brandModel, paravolNumber], () => {
            let page = 1;

            if (route.query.page && +route.query.page !== 0) {
                page = +route.query.page;
            }
            getRecords(page);
        });


        const getRecords = async (page: number, mount = false) => {
            const filterParams = {
                ...(recordStatuses.value.length > 0 && { 'status': recordStatuses.value}),
                ...(invoiceNumber.value && { invoiceNumber: invoiceNumber.value }),
                ...(brandName.value && { brandName: brandName.value }),
                ...(brandModel.value && { brandModel: brandModel.value }),
                ...(paravolNumber.value && { paravolNumber: paravolNumber.value }),
            };

            try {
                const { items, totalPages } = await apiService.getRecords(
                    page, 15,
                    { ...filterParams}
                );

                if (page > totalPages) {
                    await getRecords(totalPages);
                    return;
                }

                records.value = items;
                recordsTotalPages.value = totalPages;

                recordsCurrentPage.value = page;

                const newRoute: any = {
                    ...route,
                    query: { page, ...filterParams },
                };

                if (page === 1) {
                    delete newRoute.query.page;
                }

                if (mount) {
                    await router.replace(newRoute);
                } else {
                    await router.push(newRoute);
                }
            } catch (error) {
                // console.log(error)
            }
        };

        return {
            records,
            searchForm,
            recordsCurrentPage,
            recordsTotalPages,
            getRecords
        };
    },
};
