import { createApp } from "vue";
import dayjs from 'dayjs'

import axios from "axios";

import VueMultiselect from 'vue-multiselect'
import App from "./App.vue";

import "./index.scss";

import router from "./router";
import store from "./store";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faChevronLeft, faCircleNotch, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";

declare const window: any;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = `https://api.${window.env.APP_DOMAIN}`;

axios.interceptors.response.use(
    (res) => res,
    (error) => {
        if(error.response) {
            if(error.response.data["hydra:member"]) {
                error.message = error.response.data["hydra:member"];
            } else if(error.response.data["hydra:description"]) {
                error.message = error.response.data["hydra:description"];
            } else if(error.response.data.message) {
                error.message = error.response.data.message;
            } else if(error.response.statusText) {
                error.message = error.response.statusText;
            }
        }

        const currentRoute = router.currentRoute.value;

        if (error?.response.status == 401 && currentRoute?.name !== "login") {
            if (currentRoute) {
                store.commit("setRedirectRoute", currentRoute);
                store.commit("setCurrentUser", null);
            }
            return router.push({ name: "login" });
        }
        return Promise.reject(error);
    }
);

library.add(faChevronLeft, faRightFromBracket, faCircleNotch)

const app = createApp(App)
    .use(store)
    .use(router)

app.config.globalProperties.$dayjs = dayjs;

app.component('VueMultiselect', VueMultiselect);
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount("#app");
