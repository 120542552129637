import { RouteLocationNormalized } from "vue-router";

export const notFoundRoute = (to: RouteLocationNormalized) => ({
    ...to,
    name: "not-found",
    params: {
        pathMatch: to.path
            .slice(1, to.path.length) // remove first '/'
            .split("/"),
    },
});
