

export default {
    props: {
        totalPages: Number,
        page: Number,
        containerClass: String
    },
    setup: () => {
        return {};
    }
};
