import { useStore } from "vuex";
import { computed } from "vue";

export function useCurrentUser() {
    const store = useStore();
    return [
        computed(() => store.getters.currentUser).value,
        (user: any) => store.commit("setCurrentUser", user),
    ];
}
