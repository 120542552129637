import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "@/store";
import { notFoundRoute } from "@/helpers";

const authenticationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const requiresAuth =
        to.meta.requiresAuth ||
        to.matched.find((data) => data.meta.requiresAuth);

    if (requiresAuth) {
        if (!store.getters.hasCurrentUser) {
            if (to) {
                store.commit("setRedirectRoute", to);
            }
            return next({
                name: "login",
                params: to.params,
            });
        }

        const type = to.meta.type || to.matched.find((data) => data.meta.type);

        const currentUser = store.getters.currentUser;

        if (type && currentUser["@type"] !== type) {
            return next(notFoundRoute(to));
        }
    }
    next();
};

export default authenticationGuard;
