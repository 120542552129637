import Cookies from "js-cookie";

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import apiService from "@/services/api-service";
import store from "@/store";

declare const window: any;

const authenticationResolver = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const hasJwtHeaderPayloadCookie = !!Cookies.get(window.env.JWT_COOKIE_HP);

    const hasCurrentUser = store.getters.hasCurrentUser;

    if (hasJwtHeaderPayloadCookie && !hasCurrentUser) {
        try {
            const user = await apiService.authenticate();
            store.commit("setCurrentUser", user);
        } catch (error) {
            // Cookies.remove(window.env.JWT_COOKIE_HP, { domain: `.${window.env.DOMAIN}` });
        }
    }
    next();
};
export default authenticationResolver;
