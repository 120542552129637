
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import apiService from "@/services/api-service";
import {ComponentPublicInstance, Record} from "@/types";

import {useVuelidate} from "@vuelidate/core";
import RecordInvoice from "@/components/RecordInvoice.vue";
import {helpers, required} from "@vuelidate/validators";
import HeaderNavbar from "@/components/HeaderNavbar.vue";

import { isEqual } from "lodash";

import toastr from "toastr";

declare const window: any;

export default {
    components: {RecordInvoice, HeaderNavbar},
    beforeRouteEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        try {
            const { recordId } = to.params;
            const record = await apiService.getRecord(recordId as string);
            next(vm => (vm as ComponentPublicInstance).record = record)
        } catch (error) {
            next({ name: 'admin-home' });
        }
    },
    setup: (props: any) => {

        const record = ref<Record>();

        const brandName = ref();
        const brandModel = ref();
        const bikeType = ref();
        const bikeIsElectric = ref();
        const bikeColors = ref<any[]>([]);
        const bikeSerialNumber = ref();
        const bikePrice = ref();

        const paravolNumber = ref();

        const ownerFirstName = ref();
        const ownerLastName = ref();
        const ownerEmail = ref();
        const ownerPhone = ref();

        const invoiceNumber = ref();

        const bikeColorOptions = ref<any[]>([]);
        const bikeTypeOptions = ref<any[]>([]);

        onMounted( () => {
            apiService.getRecordColors()
                .then(records => bikeColorOptions.value = records);
            apiService.getBikeTypes()
                .then(bikeTypes => bikeTypeOptions.value = bikeTypes);
        })

        const isRecordFormChanged = computed(() => {
            const recordData = {
                brandName: record.value?.brandName || null,
                brandModel: record.value?.brandModel || null,
                colors: record.value?.colors.map(bikeColor => bikeColor['@id'] ?? {name: bikeColor.name}),
                bikeType: record.value?.bikeType,
                bikeIsElectric: record.value?.bikeIsElectric,
                ownerFirstName: record.value?.ownerFirstName || null,
                ownerLastName: record.value?.ownerLastName || null,
                ownerEmail: record.value?.ownerEmail || null,
                ownerPhone: record.value?.ownerPhone || null,
                paravolNumber: record.value?.paravolNumber || null,
                serialNumber: record.value?.serialNumber || null,
                invoiceNumber: record.value?.invoiceNumber || null,
                price: record.value?.price || null,
            }
            const formRecordData = {
                brandName: brandName.value || null,
                brandModel: brandModel.value || null,
                colors: bikeColors.value.map(bikeColor => bikeColor['@id'] ?? {name: bikeColor.name}),
                bikeType: bikeType.value,
                bikeIsElectric: bikeIsElectric.value,
                ownerFirstName: ownerFirstName.value || null,
                ownerLastName: ownerLastName.value || null,
                ownerEmail: ownerEmail.value || null,
                ownerPhone: ownerPhone.value || null,
                paravolNumber: paravolNumber.value || null,
                serialNumber: bikeSerialNumber.value || null,
                invoiceNumber: invoiceNumber.value || null,
                price: bikePrice.value || null,
            };

            return !isEqual(recordData, formRecordData);
        })

        watch(() => record.value, (record) => {
            if(record) {
                brandName.value = record?.brandName;
                brandModel.value = record?.brandModel;
                bikeType.value = record?.bikeType;
                bikeIsElectric.value = record?.bikeIsElectric;
                bikeColors.value = record.colors;
                bikePrice.value = record?.price;
                bikeSerialNumber.value = record?.serialNumber;
                invoiceNumber.value = record?.invoiceNumber;

                paravolNumber.value = record?.paravolNumber;

                ownerFirstName.value = record?.ownerFirstName;
                ownerLastName.value = record?.ownerLastName;
                ownerEmail.value = record?.ownerEmail;
                ownerPhone.value = record?.ownerPhone;

                recordForm.value.$touch();
            }
        }, { immediate: true });

        const colorBetweenOneAndTwo = helpers.withMessage('Vous devez spécifier entre une et deux couleurs.', (value: []) => value.length >= 1 && value.length <= 2);

        const recordForm = useVuelidate({
            brandName: { required },
            brandModel: { required },
            bikeColors: {
                colorBetweenOneAndTwo
            },
            bikeType: { required },
            bikeIsElectric: { required },
            bikePrice: {},
            bikeSerialNumber: {},
            invoiceNumber: {},
            ownerFirstName: { required },
            ownerLastName: { required },
            ownerEmail: { required },
            ownerPhone: { required },
            paravolNumber: { required },
        }, { brandName, brandModel, bikeColors, bikePrice, bikeSerialNumber, invoiceNumber, ownerFirstName, ownerLastName, ownerEmail, ownerPhone, bikeType, bikeIsElectric, paravolNumber});

        const submitRecordForm = async () => {
            try {
                const recordData = {
                    brandName: brandName.value || null,
                    brandModel: brandModel.value || null,
                    colors: bikeColors.value.map(bikeColor => bikeColor['@id'] ?? { name: bikeColor.name} ),
                    bikeType: bikeType.value ? bikeType.value['@id'] : null,
                    bikeIsElectric: bikeIsElectric.value,
                    ownerFirstName: ownerFirstName.value || null,
                    ownerLastName: ownerLastName.value || null,
                    ownerEmail: ownerEmail.value || null,
                    ownerPhone: ownerPhone.value || null,
                    paravolNumber: paravolNumber.value || null,
                    serialNumber: bikeSerialNumber.value || null,
                    invoiceNumber: invoiceNumber.value || null,
                    price: bikePrice.value ? bikePrice.value.toString() : null,
                };

                const recordId = record.value?.id;
                if (recordId) {
                    record.value = await apiService.editRecord(recordId, recordData);
                    toastr.success("Enregistrement enregistré.");
                }

                // recordForm.value.$reset();
            } catch (error) {
                // console.log(error)
            }
        }

        const addBikeColorOption = (bikeColorOptionName: string) => {
            const bikeColorOption = {
                name: bikeColorOptionName,
                id: bikeColorOptionName.substring(0, 2) + Math.floor((Math.random() * 10000000))
            };

            bikeColorOptions.value.push(bikeColorOption);
            bikeColors.value.push(bikeColorOption);
        }

        const validateAndSubmitRecordForm = async () => {
            recordForm.value.$touch();
            if(!recordForm.value.$invalid) {
                try {

                    const recordId = record.value?.id;

                    if (recordId) {
                        record.value = await apiService.editAndValidateRecord(recordId);
                    }
                } catch ({message}) {
                    toastr.error(message as string);
                }
            }
        }

        const closeRecord = async () => {
            try {
                const recordId = record.value?.id;

                if (recordId) {
                    record.value = await apiService.recordClosed(recordId);
                }
            } catch ({message}) {
                toastr.error(message as string);
            }
        }


        return {
            record,
            recordForm,
            submitRecordForm,
            validateAndSubmitRecordForm,
            addBikeColorOption,
            bikeColorOptions,
            bikeTypeOptions,
            isRecordFormChanged,
            closeRecord
        };
    },
};
