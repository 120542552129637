<template>
    <div class="flex justify-between">
        <div class="flex-1">
            <slot></slot>
        </div>
        <div class="flex items-center">
<!--            <img style="height: 48px;" class="mr-2" src="https://app.paravol.org/assets/img/paravol-small@2x.png"/>-->
            <div class="font-semibold text-xl">
                Oscar <small v-if="isAdmin">Admin</small>
            </div>
        </div>
        <div class="flex flex-1 justify-end">
            <div class="flex items-center">
                <div class="font-bold mr-4">{{currentUser.name}}</div>
                <button title="Déconnexion" @click="onClickDisconnect()" class="btn btn-stretch btn-oscar-light">
<!--                    <span class="mr-2">Déconnexion</span>-->
                    <font-awesome-icon icon="fa-solid fa-right-from-bracket" size="lg"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {useCurrentUser} from "@/hooks/useCurrentUser";
import Cookies from "js-cookie";
import {useRouter} from "vue-router";

export default {
    props: {
        isAdmin: Boolean,
    },
    setup: () => {
        const router = useRouter();

        const [currentUser, setCurrentUser] = useCurrentUser();

        const onClickDisconnect = () => {
            Cookies.remove(window.env.JWT_COOKIE_HP, {
                domain: `.${window.env.APP_DOMAIN}`,
            });
            setCurrentUser(null);
            router.push({ name: 'login'})
        };

        return {
            currentUser,
            onClickDisconnect,
        };
    }
}
</script>

<style scoped>

</style>
