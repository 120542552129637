import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NotFoundView from "@/views/NotFoundView.vue";
import authenticationGuard from "@/router/guards/authenticationGuard";
import authenticationResolver from "@/router/resolvers/authenticationResolver";
import AdminHomeView from "@/views/admin/AdminHomeView.vue";
import RetailerHomeView from "@/views/retailer/RetailerHomeView.vue";

import store from "@/store";
import AdminRecordsView from "@/views/admin/AdminRecordsView.vue";
import RetailerRecordsView from "@/views/retailer/RetailerRecordsView.vue";
import AdminRecordView from "@/views/admin/AdminRecordView.vue";
import RetailerRecordView from "@/views/retailer/RetailerRecordView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/admin",
        meta: {
            requiresAuth: true,
            type: "Admin",
        },
        component: AdminHomeView,
        children: [
            {
                path: "",
                name: "admin-home",
                component: AdminRecordsView,
            },
            {
                path: "records/:recordId",
                name: "admin-record",
                component: AdminRecordView,
            },
        ],
    },
    {
        path: "/",
        meta: {
            requiresAuth: true,
        },
        component: RetailerHomeView,
        beforeEnter: (to, from, next) => {
            const currentUser = store.getters.currentUser;
            if (currentUser?.["@type"] === "Admin") {
                return next({ name: "admin-home", replace: true });
            }
            next();
        },
        children: [
            {
                path: "",
                name: "retailer-home",
                component: RetailerRecordsView,
            },
            {
                path: "records/:recordId",
                name: "retailer-record",
                component: RetailerRecordView,
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFoundView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(authenticationResolver);
router.beforeEach(authenticationGuard);

export default router;
