<template>
  <span v-if="status === 'validated'"
        class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded">Validé</span>
    <span v-if="status === 'awaiting_validation'"
          class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">En attente de validation</span>
    <span v-if="status === 'missing_fields'"
          class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-500 text-white rounded">Champs manquants</span>
    <span v-if="status === 'closed'"
          class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-500 text-white rounded">Fermé</span>
</template>

<script>

export default {
    props: {
        status: String
    },
    setup: () => {
        return {}
    }
}
</script>

<style scoped>
</style>
