import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "previous", {
      page: $props.page - 1,
      disabled: $props.page <= 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass($props.containerClass),
      role: "group",
      "aria-label": "Pages"
    }, [
      ($props.totalPages > 7)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _renderSlot(_ctx.$slots, "default", {
              page: 1,
              active: $props.page === 1
            }),
            ($props.page - 1 > 3)
              ? _renderSlot(_ctx.$slots, "ellipsize", { key: 0 })
              : _createCommentVNode("", true),
            (($props.page - 1 > 3) && ($props.totalPages - $props.page > 3))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _renderSlot(_ctx.$slots, "default", {
                    page: $props.page - 1
                  }),
                  _renderSlot(_ctx.$slots, "default", {
                    page: $props.page,
                    active: true
                  }),
                  _renderSlot(_ctx.$slots, "default", {
                    page: $props.page + 1
                  })
                ], 64))
              : (($props.page - 1 <= 3))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, _renderList(4, (pageIndex) => {
                    return _renderSlot(_ctx.$slots, "default", {
                      page: pageIndex + 1,
                      active: $props.page === pageIndex + 1
                    })
                  }), 64))
                : (($props.totalPages - $props.page <= 3))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, _renderList(4, (pageIndex) => {
                      return _renderSlot(_ctx.$slots, "default", {
                        page: $props.totalPages - (5 - pageIndex),
                        active: $props.page === $props.totalPages - (5 - pageIndex)
                      })
                    }), 64))
                  : _createCommentVNode("", true),
            ($props.totalPages - $props.page > 3)
              ? _renderSlot(_ctx.$slots, "ellipsize", { key: 4 })
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {
              page: $props.totalPages,
              active: $props.page === $props.totalPages
            })
          ], 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.totalPages, (pageIndex) => {
            return _renderSlot(_ctx.$slots, "default", {
              page: pageIndex,
              active: $props.page === pageIndex
            })
          }), 256))
    ], 2),
    _renderSlot(_ctx.$slots, "next", {
      page: $props.page + 1,
      disabled: $props.page >= $props.totalPages
    })
  ], 64))
}