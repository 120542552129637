import { createStore } from "vuex";

export default createStore({
    state: {
        currentUser: null,
        redirectRoute: null,
    },
    getters: {
        currentUser: (state) => state.currentUser,
        redirectRoute: (state) => state.redirectRoute,
        hasCurrentUser: (state) => state.currentUser !== null,
    },
    mutations: {
        setCurrentUser: (state, user) => {
            state.currentUser = user;
        },
        setRedirectRoute: (state, route) => {
            state.redirectRoute = route;
        },
    },
    actions: {},
    modules: {},
});
